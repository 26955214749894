import React, { Suspense } from "react"
import { createRoot } from "react-dom/client"
import { ColorModeScript } from "@chakra-ui/react"
import "./index.css"
import App from "./App"
import Firebase, { FirebaseContext } from "./utils/Firebase"
import reportWebVitals from "./reportWebVitals"

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
  <>
    <ColorModeScript />
    <FirebaseContext.Provider value={new Firebase()}>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </FirebaseContext.Provider>
  </>
)

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
