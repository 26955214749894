/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import get from "lodash.get"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import * as Yup from "yup"
import {
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react"
import { Formik, Form, Field } from "formik"
import DatePicker, { getYear } from "react-datepicker"
import { SearchIcon } from "@chakra-ui/icons"
import {
  MdAddBox,
  MdPhone,
  MdMail,
  MdPhoneIphone,
  MdLocationOn,
  MdPerson,
  MdPersonOutline,
  MdRecentActors,
} from "react-icons/md"
import PropTypes from "prop-types"
import algoliasearch from "algoliasearch"
import { withFirebase } from "../../../utils/Firebase"
import { MAIL_URL } from "../../../utils/Constants/Functions"
import "react-datepicker/dist/react-datepicker.css"

dayjs.extend(utc)
const ClientList = ({
  firebase,
  externalLoading,
  handleClientsLoad,
  selectedClient,
  handleClientSelect,
}) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure()
  const [value, setValue] = React.useState("")
  const [startDate, setStartDate] = useState(new Date())
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(false)
  const searchClient = algoliasearch(
    "1BQBVAVKLH",
    "0a6ed2e8308617c295ce450491115792"
  )
  const index = searchClient.initIndex("active_clients")
  const years = Array.from(Array(new Date().getFullYear() - 1949), (_, i) =>
    (i + 1950).toString()
  )
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ]

  useEffect(() => {
    if (selectedClient) {
      onOpenEdit()
    }
  }, [selectedClient])

  const handleChange = (event) => {
    setValue(event.target.value)
    if (event.target.value && event.target.value.length >= 5) {
      setLoading(true)
      index.search(event.target.value).then(({ hits }) => {
        setClients(hits)
        handleClientsLoad(hits)
        setLoading(false)
      })
    }
  }

  const handleDrawerClose = () => {
    onClose()
  }

  const ExampleCustomInput = ({ value: valueT, onClick }) => (
    <Button
      type="button"
      border="1px"
      borderColor="inherit"
      p="5"
      borderRadius="4px"
      w="150px"
      background="white"
      onClick={onClick}
    >
      {valueT || "Seleccionar"}
    </Button>
  )

  return (
    <Flex direction="column" justify="flex-start" height="100%">
      <Heading fontSize="md" mb="2">
        BUSCAR CLIENTES
      </Heading>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          type="phone"
          placeholder="Cliente..."
          value={value}
          onChange={handleChange}
        />
      </InputGroup>
      <Button
        mt="10"
        leftIcon={<MdAddBox />}
        colorScheme="teal"
        variant="outline"
        onClick={onOpen}
      >
        Agregar Cliente
      </Button>
      <Drawer
        size="sm"
        isOpen={isOpenEdit}
        placement="left"
        onClick={() => {
          handleClientSelect(null)
          onCloseEdit()
        }}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader>Editar cliente</DrawerHeader>
            <DrawerBody>
              {selectedClient && (
                <Formik
                  initialValues={{
                    name: get(selectedClient, "name", ""),
                    lastName: get(selectedClient, "lastName", ""),
                    email: get(selectedClient, "email"),
                    legalId: get(selectedClient, "legalId"),
                    mobileNumber: get(selectedClient, "mobileNumber"),
                    phoneNumber: get(selectedClient, "phoneNumber"),
                    address: get(selectedClient, "address", ""),
                    legalType: get(selectedClient, "legalType"),
                    gender: get(selectedClient, "gender"),
                  }}
                  validationSchema={Yup.object({
                    legalId: Yup.string()
                      .trim()
                      .test(
                        "numbers-9-10-11",
                        "El formato no es válido",
                        (legalValue, context) => {
                          const validate =
                            context.parent.legalType === "01"
                              ? legalValue &&
                                legalValue.match(/^([1-9])\d{4}\d{4}$/) &&
                                legalValue.match(/^(?!^0+$)[a-zA-Z0-9]{3,20}$/)
                              : legalValue &&
                                legalValue.match(
                                  /^[0-9]{11}$|^[0-9]{12}$|^[a-zA-Z0-9]{6,9}$/
                                ) &&
                                legalValue.match(/^(?!^0+$)[a-zA-Z0-9]{3,20}$/)
                          return validate
                        }
                      )
                      .required("La identificacíón es requerida"),
                    name: Yup.string().required("Nombre es requerido"),
                    lastName: Yup.string().required(
                      "Al menos un apellido es requerido"
                    ),
                    phoneNumber: Yup.string()
                      .matches("^[0-9]{8}$", "El formato no es válido")
                      .required("Teléfono es requerido"),
                    mobileNumber: Yup.string()
                      .matches("^[0-9]{8}$", "El formato no es válido")
                      .nullable(),
                    email: Yup.string()
                      .email("Debe cumplir con el formato.")
                      .nullable(),
                    gender: Yup.string().required("El género es requerido"),
                  })}
                  onSubmit={async (values, actions) => {
                    try {
                      const data = {
                        ...selectedClient,
                        ...values,
                        displayName: `${values.name} ${values.lastName}`,
                        updated: dayjs().valueOf(),
                      }
                      await firebase.updateClient(data)
                      const objects = [data]
                      await index.saveObjects(objects)
                      setClients([])
                      handleClientsLoad([])
                      setValue(data.legalId)
                      onCloseEdit()
                      toast({
                        title: "Cliente modificado.",
                        description: `Se ha modificado el cliente correctamente!`,
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      })
                    } catch (error) {
                      toast({
                        title: "Error.",
                        description:
                          "Ha ocurrido un error agregando el cliente.",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                      })
                    }
                  }}
                >
                  {({ isSubmitting, dirty, isValid, setFieldValue }) => (
                    <Form>
                      <Field name="legalType">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.legalType && form.touched.legalType
                            }
                          >
                            <FormLabel htmlFor="legalType">Tipo:</FormLabel>
                            <RadioGroup
                              {...field}
                              onChange={(valuel) => {
                                setFieldValue("legalType", valuel.toString())
                              }}
                              id="legalType"
                            >
                              <Stack direction="row">
                                <Radio id="opt-1" value="01">
                                  Física
                                </Radio>
                                <Radio id="opt-2" value="03">
                                  DIMEX
                                </Radio>
                              </Stack>
                            </RadioGroup>
                            <FormErrorMessage>
                              {form.errors.legalaType}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="legalId">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.legalId && form.touched.legalId
                            }
                          >
                            <FormLabel htmlFor="legalId">
                              * Identificación:
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                fontSize="1.2em"
                                p="0"
                                zIndex="1"
                              >
                                <MdRecentActors color="gray.300" />
                              </InputLeftElement>
                              <Input
                                {...field}
                                id="legalId"
                                placeholder="00000000"
                              />
                            </InputGroup>

                            <FormErrorMessage>
                              {form.errors.legalId}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="name">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={form.errors.name && form.touched.name}
                          >
                            <FormLabel htmlFor="name">* Nombre:</FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                fontSize="1.2em"
                                p="0"
                                zIndex="1"
                              >
                                <MdPerson color="gray.300" />
                              </InputLeftElement>
                              <Input
                                {...field}
                                id="name"
                                placeholder="Nombre"
                              />
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="lastName">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.lastName && form.touched.lastName
                            }
                          >
                            <FormLabel htmlFor="lastName">
                              * Apellidos:
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                fontSize="1.2em"
                                p="0"
                                zIndex="1"
                              >
                                <MdPersonOutline color="gray.300" />
                              </InputLeftElement>
                              <Input
                                {...field}
                                id="lastName"
                                placeholder="Apellidos"
                              />
                            </InputGroup>

                            <FormErrorMessage>
                              {form.errors.lastName}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="phoneNumber">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.phoneNumber &&
                              form.touched.phoneNumber
                            }
                          >
                            <FormLabel htmlFor="phoneNumber">
                              * Teléfono 1:
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                fontSize="1.2em"
                                p="0"
                                zIndex="1"
                              >
                                <MdPhone color="gray.300" />
                              </InputLeftElement>
                              <Input
                                {...field}
                                id="phoneNumber"
                                type="phone"
                                placeholder="22222222"
                              />
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.phoneNumber}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="mobileNumber">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.mobileNumber &&
                              form.touched.mobileNumber
                            }
                          >
                            <FormLabel htmlFor="mobileNumber">
                              Teléfono 2:
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                fontSize="1.2em"
                                p="0"
                                zIndex="1"
                              >
                                <MdPhoneIphone color="gray.300" />
                              </InputLeftElement>
                              <Input
                                {...field}
                                id="mobileNumber"
                                type="phone"
                                placeholder="88888888"
                              />
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.mobileNumber}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="email">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={form.errors.email && form.touched.email}
                          >
                            <FormLabel htmlFor="email">
                              Correo Electrónico:
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                fontSize="1.2em"
                                p="0"
                                zIndex="1"
                              >
                                <MdMail color="gray.300" />
                              </InputLeftElement>
                              <Input
                                {...field}
                                type="email"
                                id="email"
                                placeholder="correo@test.com"
                              />
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="address">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.address && form.touched.address
                            }
                          >
                            <FormLabel htmlFor="address">Dirección:</FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                fontSize="1.2em"
                                p="0"
                                zIndex="1"
                              >
                                <MdLocationOn color="gray.300" />
                              </InputLeftElement>
                              <Input
                                {...field}
                                id="address"
                                placeholder="Dirección"
                              />
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.address}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="gender">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.gender && form.touched.gender
                            }
                          >
                            <FormLabel htmlFor="gender">* Género:</FormLabel>
                            <RadioGroup
                              {...field}
                              onChange={(valuel) => {
                                setFieldValue("gender", valuel)
                              }}
                              id="gender"
                            >
                              <Stack direction="row">
                                <Radio id="opt-gen-1" value="M">
                                  Masculino
                                </Radio>
                                <Radio id="opt-gen-2" value="F">
                                  Femenino
                                </Radio>
                              </Stack>
                            </RadioGroup>
                            <FormErrorMessage>
                              {form.errors.gender}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Button
                        mt={4}
                        colorScheme="teal"
                        isLoading={isSubmitting}
                        type="submit"
                        disabled={!dirty || isSubmitting || !isValid}
                      >
                        Modificar
                      </Button>
                    </Form>
                  )}
                </Formik>
              )}
            </DrawerBody>
            <DrawerFooter>
              <Button
                variant="outline"
                mr={3}
                onClick={() => {
                  handleClientSelect(null)
                  onCloseEdit()
                }}
              >
                Cancelar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClick={handleDrawerClose}
        size="sm"
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader>Crear un cliente</DrawerHeader>
            <DrawerBody>
              <Formik
                initialValues={{
                  name: undefined,
                  lastName: undefined,
                  email: "",
                  legalId: undefined,
                  mobileNumber: "",
                  phoneNumber: undefined,
                  address: "",
                  legalType: "01",
                  gender: "M",
                  birthday: undefined,
                }}
                validationSchema={Yup.object({
                  legalId: Yup.string()
                    .trim()
                    .test(
                      "numbers-9-10-11",
                      "El formato no es válido",
                      (legalValue, context) => {
                        const validate =
                          context.parent.legalType === "01"
                            ? legalValue &&
                              legalValue.match(/^([1-9])\d{4}\d{4}$/) &&
                              legalValue.match(/^(?!^0+$)[a-zA-Z0-9]{3,20}$/)
                            : legalValue &&
                              legalValue.match(
                                /^[0-9]{11}$|^[0-9]{12}$|^[a-zA-Z0-9]{6,9}$/
                              ) &&
                              legalValue.match(/^(?!^0+$)[a-zA-Z0-9]{3,20}$/)
                        return validate
                      }
                    )
                    .required("La identificacíón es requerida"),
                  name: Yup.string().required("Nombre es requerido"),
                  lastName: Yup.string().required(
                    "Al menos un apellido es requerido"
                  ),
                  phoneNumber: Yup.string()
                    .matches("^[0-9]{8}$", "El formato no es válido")
                    .required("Teléfono es requerido"),
                  mobileNumber: Yup.string()
                    .matches("^[0-9]{8}$", "El formato no es válido")
                    .nullable(),
                  email: Yup.string()
                    .email("Debe cumplir con el formato.")
                    .nullable(),
                  gender: Yup.string().required("El género es requerido"),
                })}
                onSubmit={async (values, actions) => {
                  try {
                    const cl = await firebase.addClients({
                      ...values,
                      birthday: values.birthday
                        ? dayjs(values.birthday).valueOf()
                        : null,
                      created: dayjs().valueOf(),
                    })
                    if (values.email) {
                      fetch(MAIL_URL, {
                        method: "POST",
                        body: JSON.stringify({
                          data: {
                            templateId: 4993611, // welcome template
                            email: values.email,
                          },
                        }),
                        headers: {
                          Authorization: `Bearer ${cl.token}`,
                          "Content-Type": "application/json",
                        },
                      })
                    }
                    actions.setSubmitting(false)
                    toast({
                      title: "Cliente agregado.",
                      description: `Se ha agregado el cliente correctamente!, ${cl.id}`,
                      status: "success",
                      duration: 4000,
                      isClosable: true,
                    })
                    onClose()
                  } catch (error) {
                    toast({
                      title: "Error.",
                      description: "Ha ocurrido un error agregando el cliente.",
                      status: "error",
                      duration: 4000,
                      isClosable: true,
                    })
                  }
                }}
              >
                {({ isSubmitting, dirty, isValid, setFieldValue }) => (
                  <Form>
                    <Field name="legalType">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.legalType && form.touched.legalType
                          }
                        >
                          <FormLabel htmlFor="legalType">Tipo:</FormLabel>
                          <RadioGroup
                            {...field}
                            onChange={(valuel) => {
                              setFieldValue("legalType", valuel.toString())
                            }}
                            id="legalType"
                          >
                            <Stack direction="row">
                              <Radio id="opt-b-1" value="01">
                                Física
                              </Radio>
                              <Radio id="opt-b-2" value="03">
                                DIMEX
                              </Radio>
                            </Stack>
                          </RadioGroup>
                          <FormErrorMessage>
                            {form.errors.gender}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="legalId">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.legalId && form.touched.legalId
                          }
                        >
                          <FormLabel htmlFor="legalId">
                            Identificación:
                          </FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              fontSize="1.2em"
                              p="0"
                              zIndex="1"
                            >
                              <MdRecentActors color="gray.300" />
                            </InputLeftElement>
                            <Input
                              {...field}
                              id="legalId"
                              placeholder="00000000"
                            />
                          </InputGroup>

                          <FormErrorMessage>
                            {form.errors.legalId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                        >
                          <FormLabel htmlFor="name">Nombre:</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              fontSize="1.2em"
                              p="0"
                              zIndex="1"
                            >
                              <MdPerson color="gray.300" />
                            </InputLeftElement>
                            <Input {...field} id="name" placeholder="Nombre" />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="lastName">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.lastName && form.touched.lastName
                          }
                        >
                          <FormLabel htmlFor="lastName">Apellidos:</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              fontSize="1.2em"
                              p="0"
                              zIndex="1"
                            >
                              <MdPersonOutline color="gray.300" />
                            </InputLeftElement>
                            <Input
                              {...field}
                              id="lastName"
                              placeholder="Apellidos"
                            />
                          </InputGroup>

                          <FormErrorMessage>
                            {form.errors.lastName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="phoneNumber">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.phoneNumber && form.touched.phoneNumber
                          }
                        >
                          <FormLabel htmlFor="phoneNumber">
                            Teléfono 1:
                          </FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              fontSize="1.2em"
                              p="0"
                              zIndex="1"
                            >
                              <MdPhone color="gray.300" />
                            </InputLeftElement>
                            <Input
                              {...field}
                              id="phoneNumber"
                              type="phone"
                              placeholder="22222222"
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.phoneNumber}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="mobileNumber">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.mobileNumber &&
                            form.touched.mobileNumber
                          }
                        >
                          <FormLabel htmlFor="mobileNumber">
                            Teléfono 2:
                          </FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              fontSize="1.2em"
                              p="0"
                              zIndex="1"
                            >
                              <MdPhoneIphone color="gray.300" />
                            </InputLeftElement>
                            <Input
                              {...field}
                              id="mobileNumber"
                              type="phone"
                              placeholder="88888888"
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.mobileNumber}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <FormLabel htmlFor="email">
                            Correo Electrónico:
                          </FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              fontSize="1.2em"
                              p="0"
                              zIndex="1"
                            >
                              <MdMail color="gray.300" />
                            </InputLeftElement>
                            <Input
                              {...field}
                              type="email"
                              id="email"
                              placeholder="correo@test.com"
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="address">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.address && form.touched.address
                          }
                        >
                          <FormLabel htmlFor="address">Dirección:</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              fontSize="1.2em"
                              p="0"
                              zIndex="1"
                            >
                              <MdLocationOn color="gray.300" />
                            </InputLeftElement>
                            <Input
                              {...field}
                              id="address"
                              placeholder="Dirección"
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.address}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="gender">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.gender && form.touched.gender}
                        >
                          <FormLabel htmlFor="gender">Género:</FormLabel>
                          <RadioGroup
                            {...field}
                            onChange={(valuel) => {
                              setFieldValue("gender", valuel.toString())
                            }}
                            id="gender"
                          >
                            <Stack direction="row">
                              <Radio id="opt-gen-1" value="M">
                                Masculino
                              </Radio>
                              <Radio id="opt-gen-2" value="F">
                                Femenino
                              </Radio>
                            </Stack>
                          </RadioGroup>
                          <FormErrorMessage>
                            {form.errors.legalaType}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="birthday">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.birthday && form.touched.birthday
                          }
                        >
                          <FormLabel htmlFor="birthday">
                            Fecha Nacimiento:
                          </FormLabel>
                          <DatePicker
                            isClearable
                            dateFormat="dd/MM/yyyy"
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            }) => (
                              <div
                                style={{
                                  margin: 10,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  type="button"
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  {"<"}
                                </button>
                                <select
                                  value={dayjs(date).format("YYYY")}
                                  onChange={({ target: { value: valueY } }) => {
                                    changeYear(valueY)
                                  }}
                                >
                                  {years.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  value={months[dayjs(date).format("MMMM")]}
                                  onChange={({ target: { value: valueM } }) => {
                                    changeMonth(months.indexOf(valueM))
                                  }}
                                >
                                  {months.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                                <button
                                  type="button"
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  {">"}
                                </button>
                              </div>
                            )}
                            customInput={<ExampleCustomInput />}
                            selected={field.value}
                            onChange={(date) => {
                              setFieldValue("birthday", date)
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.birthday}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      mt={4}
                      colorScheme="teal"
                      isLoading={isSubmitting}
                      type="submit"
                      disabled={!dirty || isSubmitting || !isValid}
                    >
                      Crear
                    </Button>
                  </Form>
                )}
              </Formik>
            </DrawerBody>
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={handleDrawerClose}>
                Cancelar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Flex>
  )
}

ClientList.propTypes = {
  handleClientSelect: PropTypes.func.isRequired,
  selectedClient: PropTypes.shape().isRequired,
  externalLoading: PropTypes.bool.isRequired,
  firebase: PropTypes.shape().isRequired,
  handleClientsLoad: PropTypes.func.isRequired,
}

export default withFirebase(ClientList)
