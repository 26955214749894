/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { compose } from "recompose"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import {
  Image,
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Spacer,
  Text,
} from "@chakra-ui/react"

import logoCentro from "../../../static/images/logo-clinica-rivera.png"
import ErrorMessage from "../../atoms/ErrorMessage"
import { withFirebase } from "../../../utils/Firebase"
import * as ROUTES from "../../../utils/Constants/Routes"
// import { version } from "../../../../package.json"

const LoginFormBase = ({ firebase, history }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [isLogginIn, setIsLogginIn] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault()
    setIsLogginIn(true)
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        history.push(ROUTES.HOME)
      })
      .catch((err) => {
        // alert(`Error: ${err}`)
        setError(err.message)
        // this.setState({ error: err, isLogginIn: false })
        setIsLogginIn(false)
      })
  }
  return (
    <Flex
      direction="column"
      width="full"
      heigh="100%"
      justifyContent="center"
      alignItems="center"
      my="2"
    >
      <Flex width="350px" my="10" justifyContent="center" alignItems="center">
        <Image
          width={400}
          src={logoCentro}
          alt="Logo Clinica Rivera"
          style={{ margin: "0, 10" }}
        />
      </Flex>
      <Box p={8} width="700px" borderWidth={1} borderRadius={8} boxShadow="lg">
        <Box textAlign="center">
          <Heading color="brand.green.300">Iniciar Sesión</Heading>
        </Box>
        <Box my={4} textAlign="left">
          <form onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>Usuario</FormLabel>
              <Input
                defaultValue={email}
                type="email"
                placeholder="test@test.com"
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
            </FormControl>
            <FormControl mt={6} isRequired>
              <FormLabel>Contraseña</FormLabel>
              <Input
                defaultValue={password}
                type="password"
                placeholder="*******"
                onChange={(event) => setPassword(event.currentTarget.value)}
              />
            </FormControl>
            <Button
              type="submit"
              width="200px"
              mt={6}
              isLoading={isLogginIn}
              disabled={isLogginIn}
              bg="brand.green.300"
              color="white"
            >
              Iniciar Sesión
            </Button>
            {error && <ErrorMessage message={error} />}
          </form>
        </Box>
      </Box>
      <Flex mt="10px">
        <Text fontSize="sm" color="gray.500">
          Version: 1.0.0
        </Text>
      </Flex>
    </Flex>
  )
}

LoginFormBase.propTypes = {
  firebase: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
}

export default compose(withRouter, withFirebase)(LoginFormBase)
// export default LoginForm
